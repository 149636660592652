import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

export default function BasicRating({icon}) {
  const [value, setValue] = React.useState(icon.active);
  return (
    <Box
      sx={{
        '& > legend': { mt: 2 },
      }}
      
    >
      
      <Rating
       name="customized-1" defaultValue={1} max={1}
       style={{color:icon.color}}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
    
    </Box>
  );
}