
import React from "react";
import { Grid, Card } from "@material-ui/core";

const CustomCartersKPICard = ({ data }) => {
 
  return (
    <div style={{ flex: 1, overflow: "auto" }}>
    <Grid container >
      <div>
      
            <Grid item>
              <Card
                style={{
                  margin: "10px",
                  padding:"15px",
                  width: "auto",
                  height: data.style.height || "260px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "right",
                  alignItems: "vertical",
                }}
              >

                {data.data.map((doc)=>{
                  return(
                    <span style={doc.style}>{doc.text}</span>
                  )
                })}
                
              </Card>
            </Grid>
        
      </div>
    </Grid>
    </div>
  );
};
export default CustomCartersKPICard;
