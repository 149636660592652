import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAction from "../../../utils/useAction";
import Select from "react-select";
const customStyles = ({ controlWidth, menuWidth }) => ({
	control: (provided, state) => ({
		...provided,
		borderColor: state.isFocused ? "black" : provided.borderColor,
		boxShadow: state.isFocused ? "none" : provided.boxShadow,
		height: "25px",
		width: controlWidth || "120px",
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 9999,
		width: menuWidth || "150px",
	}),
	option: (provided) => ({
		...provided,
		fontSize: "0.75rem",
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: "none",
	}),
	menuPortal: (base) => ({ ...base, zIndex: 9999 }),
});
const getOptions = (optionsStr) => {
	const options = optionsStr.trim().split(",");
	return options.map((str) => {
		const value = str.trim();
		return {
			value,
			label: value,
		};
	});
};
export function CellDropdown(props) {
	const [flagEffect] = useState(() =>
		props.hasOwnProperty("flagEffect") ? props.flagEffect : true
	);
	const dispatch = useDispatch();
	const { tableInfo } = useSelector((state) => state.home);
	const invokeAction = useAction();
	const [dropdowns] = useState(() => {
		let containers = props.data[`${props.colDef.field}#options_0`];
		if (!containers) return [];
		containers = containers.trim()?.split(":");
		return containers.map((optionsStr) => getOptions(optionsStr));
	});

	const [selected, setSelected] = useState([]);
	const handleAction = (
		selectedValue,
		index,
		actions,
		defaultAction,
		rowId
	) => {
		const optionIndex = dropdowns[index].findIndex(
			(option) => selectedValue === option.value
		);
		if (optionIndex !== -1 && optionIndex < actions.length) {
			if (actions[optionIndex] === "default") {
				invokeAction(defaultAction, [rowId], selectedValue);
			} else {
				invokeAction(actions[optionIndex], [rowId], selectedValue);
			}
		}
	};
	const handleChange = useCallback(
		(newOption, index, params) => {
			const arr = [...selected];
			arr[index] = newOption;
			const rows = [...tableInfo[params.parent_table_key]];
			let row = null;
			if (params.data.hasOwnProperty("id")) {
				row = rows.find((row) => row.id === params.data.id);
			} else {
				row = rows[params.rowIndex];
			}
			let fieldLabel = arr.map((obj) => obj.label).join(",");
			row[params.colDef.field] = `component-dropdown-${fieldLabel}`;
			dispatch({
				type: "TABLE_DATA",
				payload: { [params.parent_table_key]: [...rows] },
			});
			params.api.stopEditing();
			if (params.actions) {
				handleAction(
					newOption.value,
					index,
					params.actions,
					params?.defaultAction,
					row["id"] || params.rowIndex
				);
			}
		},
		[selected, tableInfo]
	);

	const selects = useMemo(
		() =>
			dropdowns.map((options, index) => {
				if (flagEffect && props.data.hasOwnProperty("#disable_dropdown")) {
					if (props.data["#disable_dropdown"] === "Y") {
						return (
							(selected.length > 0 && selected[index].label) || options[0].label
						);
					}
				}
				return (
					<Select
						options={options}
						value={selected[index] || options[0]}
						onChange={(value) => handleChange(value, index, props)}
						styles={customStyles(props)}
						menuShouldBlockScroll={true}
						menuPlacement="auto"
						menuPortalTarget={document.body}
						isSearchable={false}
						maxMenuHeight={200}
					/>
				);
			}),
		[selected, dropdowns, props]
	);

	useEffect(() => {
		const labels = props.value?.trim().split(",");
		setSelected((prev) => {
			return dropdowns.map((options, index) => {
				const option = options.find(
					({ label }) => label === labels[index].trim()
				);
				return option || prev[index];
			});
		});
	}, [props]);
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				gap: "5px",
				position: "relative",
			}}>
			{selects}
		</div>
	);
}
export default function Renderer(props) {
	const [hiddens, setHiddens] = useState(() => {
		if (props.data.hasOwnProperty("#hide_dropdown_multi")) {
			const fields = props.data["#hide_dropdown_multi"];
			if (fields) {
				const set = new Set(fields.split(","));
				return set;
			}
			return null;
		}
		return null;
	});
	if (hiddens && hiddens.has(props.colDef.field)) {
		return "";
	}
	return CellDropdown(props);
}
