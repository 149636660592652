import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ComponentResolver from "./ComponentResolver";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import TabPanel from "../CustomComponents/TabPanel";
import cloneDeep from "lodash/cloneDeep";
import { getMultipleModelsData } from "../action";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabResolver = (props) => {
  // const {
  //   pageObject: { tabs },
  // } = props;

  const tabs = props.pageObject.tabs.filter((val) => {
    return val.showOnClickButton === undefined;
  });
  const componentsList = props.pageObject.components;
  const [tabsList, setTabsList] = useState([]);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [otherComponentsRequired, setOtherComponentsRequired] = useState(
    props.pageObject.otherComponentsRequired
  );
  const {
    dependentInfo,
    tabRedirect,
    showActiveTab,
    tabIndexValue,
    redirectedTabIndexValue,
    dynamicTabContent,
    newDynamicTabIndexValue,
  } = useSelector((state) => state.home);

  useEffect(() => {
    if (tabRedirect["isDependentOn"]) {
      const newTabsList = tabs.filter((obj) => {
        return (
          obj["dependentOn"] === undefined ||
          dependentInfo[`${obj[`dependentOn`]}`] === true
        );
      });
      setTabsList([...newTabsList]);
      // const newTabs = tabsList.filter(obj=> ( ( !obj['dependentOn'] || obj['dependentOn'] === dependentInfo[`${obj[`dependentOn`]}`] ))
    } else {
      setTabsList([...tabs]);
    }
    setOtherComponentsRequired(
      props.pageObject.otherComponentsRequired || false
    );
    if(props.pageObject && props.pageObject['default_api_calls']){
      getMultipleModelsData({ model_names: props.pageObject['default_api_calls'] }).then((res) => {
          dispatch({
            type: "TABLE_DATA",
            payload: res,
          });
      });
    }
  }, [props.pageObject.tabs]);

  useEffect(() => {
    if (props.pageObject.initialSelectedIndex) {
      setTabIndex(Number(props.pageObject.initialSelectedIndex));
    }
    if (redirectedTabIndexValue && props.pageObject.redirectionOnClick)
      setTabIndex(Number(redirectedTabIndexValue));
  }, [
    redirectedTabIndexValue,
    tabsList,
    props.pageObject.initialSelectedIndex,
  ]);

  useEffect(() => {
    if (Object.keys(tabRedirect).length > 0) {
      if (
        tabsList.map((obj) => obj.label).includes(tabRedirect.parentTabValue)
      ) {
        setTabIndex(tabRedirect.parentTabIndex);
      }
      if (
        tabsList.map((obj) => obj.label).includes(tabRedirect.activeTabValue)
      ) {
        setTabIndex(tabRedirect.activeTabIndex);
      }
    }
  }, [tabRedirect, tabsList]);

  useEffect(() => {
    if (showActiveTab) {
      setTabsList(props.pageObject.tabs);
      setTabIndex(Number(tabIndexValue));
    }
  }, [showActiveTab]);

  const insert = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];

  useEffect(() => {
    if (dynamicTabContent) {
      let tabListValue = cloneDeep(tabsList);
      if (tabListValue[tabListValue.length - 2] !== undefined) {
        let newTabValue =
          Number(tabListValue[tabListValue.length - 2]?.key) + 1;
        let newTab = {
          label: "Scenario" + newTabValue,
          showOnClickButton: true,
          title: "Scenario " + newTabValue,
          value: "Scenario " + newTabValue,
          key: newTabValue,
          components: tabListValue[tabListValue.length - 2]?.components,
        };
        let newTabList = insert(tabListValue, tabListValue.length - 1, newTab);
        setTabIndex(Number(newTabValue));
        setTabsList(newTabList);
      }
    }
  }, [dynamicTabContent, newDynamicTabIndexValue]);

  const handleTabChange = (_, newValue) => {
    if (props.pageObject.redirectionOnClick) {
      dispatch({
        type: "REDIRECT_TO_NEW_TAB",
        payload: { tabIndexValue: newValue },
      });
    }
    let params = {};
    if (props.pageObject.isTabDataSwitching && newValue === 1) {
      params[`${newValue}`] = true;
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    } else if (dependentInfo[`1`]) {
    }
    setTabIndex(newValue);
    props.pageObject.tabs.map((obj,index) => {
      let params = {};
      if(obj.enableKeys) {
        obj.enableKeys.map((key) => {
          params[key] = true;
        })
      }
      if(obj.disableKeys) {
        obj.disableKeys.map((key) => {
          params[key] = false;
        })
      }
      if(props?.pageObject?.isTabDependentOn){
        if(index === newValue){
          params[`${obj.label}`] = true;
        }else{
          params[`${obj.label}`] = false;
        }
      }
      dispatch({
        type: "DEPENDENT_COMPONENTS",
        payload: params,
      });
    })
  };

  const renderTabs = (obj) => {
    if (obj[`dependentOn`]) {
      if (dependentInfo[`${obj[`dependentOn`]}`]) {
        return (
          <Tab
            label={obj.label}
            {...a11yProps(0)}
            style={{
              textTransform: "none",
            }}
          />
        );
      }
    } else {
      return (
        <Tab
          label={obj.label}
          {...a11yProps(0)}
          style={{
            textTransform: "none",
          }}
        />
      );
    }
  };

  return (
    <Box sx={{ width: "100%", ...props.pageObject.style  }}>
      {otherComponentsRequired ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={6} xl={7}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  paddingTop: "10px",
                }}
              >
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                >
                  {tabsList.map((obj, index) => renderTabs(obj))}
                </Tabs>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={6}
              xl={5}
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              {tabsList.map((obj, index) => (
                <>
                  {tabIndex === index ? (
                    <ComponentResolver
                      pageObject={{ components: obj.otherComponents }}
                    />
                  ) : null}
                </>
              ))}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                {tabsList.map((obj, index) => (
                  <Tab
                    label={obj.label}
                    {...a11yProps(0)}
                    style={{
                      textTransform: "none",
                    }}
                    disabled={obj.disable}
                    sx={{fontWeight:"bold"}}
                  />
                ))}
                <ComponentResolver
                  pageObject={{ components: componentsList }}
                />
              </Tabs>
            </Box>
          </Grid>
        </>
      )}

      <Grid item xs={12} >
        {tabsList.map((mainTab, index) => (
          <>
            <TabPanel value={tabIndex} index={index}>
              <ComponentResolver pageObject={mainTab} />
            </TabPanel>
          </>
        ))}
      </Grid>
    </Box>
  );
};

export default TabResolver;
