import React, { useEffect, useState } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import Home from "../pages/Home";
import Preview from "../pages/Preview";
import MapWrapper from "../openlayers/components/MapWrapper";
import NotFound from "../components/NotFound";
import {
	fetchFilterDataAction,
	fetchFormDataAction,
	getMultipleModelsData,
	refreshUserSessionAction,
	setTableConfigAction,
} from "../pages/Home/action";
import { useDispatch, useSelector } from "react-redux";
import ImpactMap from "../openlayers/components/ImpactMap";
import technical_issue_img from "../assets/images/technical_issues.png";

export const AppRoutingModule = (props) => {
	const [pageConfig, setPageConfig] = useState([]);
	const [techError, setTechError] = useState(false);
	const dispatch = useDispatch();
	const { formMasterValuesData, filterMenuData, isUserSessionActive } =
		useSelector((state) => state.filter);
	const getConfigData = async () => {
		const pages = await getMultipleModelsData({ model_names: ["tb_pages"] });
		// These values coming from Google sheets
		if (!pages?.err) {
			if (formMasterValuesData.length === 0) {
				dispatch(
					fetchFormDataAction({
						model_names: ["tb_form_master", "tb_form_master_values"],
					}),
				);
				dispatch(
					setTableConfigAction({
						model_names: ["table_config"],
					}),
				);
			}
			if (filterMenuData.length === 0) {
				dispatch(
					fetchFilterDataAction({
						model_names: ["tb_filter_master_copy", "tb_filter_master_values"],
					}),
				);
			}
			setPageConfig(pages.tb_pages);
		} else {
			setTechError(true);
		}
	};

	useEffect(async () => {
		const refresh_cache = await dispatch(refreshUserSessionAction({}));
		// getConfigData();
	}, []);
	useEffect(() => {
		if (isUserSessionActive) {
			getConfigData();
		}
	}, [isUserSessionActive]);

	return (
		<>
			{techError ? (
				<div className="tech-error">
					<img src={technical_issue_img} alt="error" />
				</div>
			) : (
				<>
					<Router>
						<Routes>
							{pageConfig?.map((item) => {
								// Adding routes from google sheets tb_pages
								return (
									<Route
										key={item.url}
										path={`/:client/:product/${item.url}`}
										element={<Home pageConfig={item} />}
									/>
								);
							})}
							<Route path={`/:client/:product/preview`} element={<Preview />} />
							<Route path={`/not-found`} element={<NotFound />} />
							<Route path={"map"} element={<MapWrapper />} />
							<Route path={"store-map"} element={<ImpactMap />} />
						</Routes>
					</Router>
				</>
			)}
		</>
	);
};
