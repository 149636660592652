import ReactMuiDatePicker from "./ReactMuiDatePicker";

export default function CellDateRange(params) {
  function renderDates(str) {
    return str.split(",").map((value) => {
      return <ReactMuiDatePicker value={value} />;
    });
  }
  return params.value ? (
    <div style={{ display: "flex", gap: "10px" }}>
      {renderDates(params.value)}
    </div>
  ) : null;
}
