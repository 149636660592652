import ComponentResolver from "../../Home/Resolver/ComponentResolver";
import React, { useState, useEffect } from "react";
import Tippy from "@tippyjs/react";
import {
  Grid,
  CardContent,
  Box,
  Card,
  Typography,
  CardActions,
} from "@material-ui/core";
import BasicRating from "./Actionstar";
import Text from "../../../utils/Text";
import { useDispatch } from "react-redux";
const defaultCardStyle = {
  display: "flex",
  flexDirection: "column",
  height: "170px",
  padding: "15px",
  border: "1px solid #c8ced7",
  boxShadow: "0px 0px 6px #00000029"
};
const ActionKPICard = (props) => {
  const { data } = props;
  const dispatch = useDispatch()
  const sizing = data?.gridSizing || { xs: 12, sm: 6, md: 6, lg: 4 };
  useEffect(() => {
    if(props.data && props.data.isStoreRequired){
      let params = {}
      params[`${props.data.name}`] = props.data.data
      dispatch({
        type: "FORM_EDITED_VALUES",
        payload: params,
      });
    }
    
  }, [])
  
  return (
    <Grid container spacing={2}>
      {data.data.map((ele) => {
        return ele.SKUCard ? (
          ele.SKUCard.map((ele) => {
            return (
              <Grid item sx={1}>
                <Card
                  style={{
                    width: "290px",
                    height: "190px",
                    margin: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "-20px",
                      justifyContent: "space-between",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: "11px" }}>
                        {ele.title1}
                      </Typography>
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "10px" }}
                      >
                        {ele.value1}
                      </Typography>
                    </CardContent>

                    <CardContent>
                      <Typography style={{ fontSize: "11px" }}>
                        {ele.title2}
                      </Typography>
                      <Typography
                        style={{ color: "#0055AF", fontSize: "10px" }}
                      >
                        {ele.value2}
                      </Typography>
                    </CardContent>
                  </div>
                  <CardContent>
                    <Typography style={{ fontSize: "11px" }}>
                      {ele.description}
                    </Typography>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "11px" }}
                    >
                      {ele.description_value}
                    </Typography>
                  </CardContent>
                  <CardActions
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <ComponentResolver
                      pageObject={ele.btntitle}
                      redirectFrom="Home"
                    />
                    <Tippy
                      content={
                        <span
                          style={{
                            backgroundColor: "grey",
                            color: "white",
                            padding: "10px",
                            fontSize: "10px",
                          }}
                        >
                          Add as hero SKU
                        </span>
                      }
                    >
                      <p>{ele.icon && <BasicRating icon={ele.icon} />}</p>
                    </Tippy>
                  </CardActions>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Grid item {...sizing}>
            <Card
              style={{
                ...defaultCardStyle,
                ...data?.cardStyle,
              }}
            >
              <div style={{ flexGrow: 1 }}>
                <Text style={{ fontSize: "16px" }} weight="bold">
                  {ele.title}
                </Text>
                <Text style={{ fontSize: "14px", whiteSpace: "normal" }}>
                  {ele.subtitle}
                </Text>
              </div>
              <ComponentResolver pageObject={ele.btntitle} spread={true} />
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ActionKPICard;
