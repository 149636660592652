import { Step, StepButton, Stepper, StepContent } from "@mui/material";
import ComponentResolver from "../../pages/Home/Resolver/ComponentResolver";
const EmptyIcon = ({ active }) => {
  return (
    <span
      style={{
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        backgroundColor: active ? "#1976d2" : "#e0e0e0",
      }}
    ></span>
  );
};
const Steppers = (props) => {
  return (
    <Stepper
      nonLinear={props.data.nonLinear ? props.data.nonLinear.value : true}
      activeStep={props.data.activeStep}
      orientation={props.data.orientation}
      alternativeLabel={props.data.style ? false : true}
      style={props.data.style}
    >
      {props.data.steps.map((label, index) => (
        <Step key={label}>
          {props.data.customIcon ? (
            <StepButton
              icon={<EmptyIcon active={props.data.activeStep == index} />}
            >
              {label}
            </StepButton>
          ) : (
            <StepButton>{label}</StepButton>
          )}
          <StepContent>
            <ComponentResolver pageObject={{ components: props.data.content }} />
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default Steppers;
