import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Tooltip, Badge } from "impact-ui";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from "@mui/icons-material/Add";
import { CustomModal } from "../../../pages/Home/CustomComponents/customModal";

export const GetDetailCartersContent = (event) => {
	let [modalOpen, setModalOpen] = useState(false);
	const currDt = new Date();
	const navigate = useNavigate();
	return (
		<div className="event-content">
			<div className="event-title-wrapper">
				<span className="status-indicator"></span>
				<p className="event-title">{event.name}</p>
			</div>
			<div className="event-detail">
				{event.eventRunningStatus == "Upcoming" &&
				moment().isBefore(moment(event.submit_offer_by)) &&
				moment(event.submit_offer_by).diff(moment(currDt), "days") < 5 ? (
					<Badge
						label={`${moment(event.submit_offer_by).diff(
							moment(currDt),
							"days"
						)} days to submission lock`}
						variant="error"
						outline
					/>
				) : null}
			</div>
			<div className="event-detail">
				<CalendarMonthIcon />
				<p>
					{event.start_date} - {event.end_date}
				</p>
			</div>
			<div className="event-detail">
				<DescriptionOutlinedIcon />
				<p>
					{event?.status === "epoc"
						? "Submit EPOC by"
						: event?.status === "firstClr" || event?.status === "furtherClr"
						? "Submit Markdown by"
						: "Submit Offers By"}{" "}
					- {event.submitDate1} - {event.SubmitTime1}
				</p>
			</div>
			<div className="event-footer">
				<React.Fragment>
					<Tooltip text="Edit" placement="top">
						<EditIcon
							disabled={!event.editiconLink}
							onClick={
								!event.editiconLink
									? () => {}
									: () =>
											navigate(
												"/" +
													window?.location?.pathname?.split("/")[1] +
													"/" +
													window?.location?.pathname?.split("/")[2] +
													event.editiconLink
											)
							}
						/>
					</Tooltip>
					<Tooltip text="Delete" placement="top">
						<DeleteIcon
							disabled={true} //moment(currDt).isSameOrAfter(moment(event?.start_date))
						/>
					</Tooltip>
					<Tooltip text="Copy" placement="top">
						<ContentCopyIcon
							disabled={!event.copyIconAction}
							onClick={
								!event.copyIconAction ? () => {} : () => setModalOpen(true)
							}
						/>
					</Tooltip>
					{(event?.status === "ongoing" || event?.status === "upcoming") && (
						<Tooltip text="Add" placement="top">
							<AddIcon
								disabled={!event.addIconLink}
								onClick={
									!event.addIconLink
										? () => {}
										: () =>
												navigate(
													"/" +
														window?.location?.pathname?.split("/")[1] +
														"/" +
														window?.location?.pathname?.split("/")[2] +
														event.addIconLink
												)
								}
							/>
						</Tooltip>
					)}
					{event.copyIconAction && (
						<CustomModal
							components={event.copyIconAction[0].action.components}
							footer_actions={event.copyIconAction[0].action.footer_actions}
							show={modalOpen}
						/>
					)}
				</React.Fragment>
			</div>
		</div>
	);
};
