import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Table } from "../../../components/Table";
import CustomActionButton from "./CustomActionButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextFieldWithLabel from "../../../components/TextField/TextFieldWithLabel";
import { getDynmaicColumns } from "../../../components/Table/columns";

const VerticleTables = (props) => {
	const { enabledTable,pageConfigData } = useSelector((state) => state.home);
	const { formMasterValuesData, tableConfig } = useSelector(
		(state) => state.filter
	  );
	const dispatch = useDispatch();
	const [showChildTable, setShowChildTable] = useState(false);
	const [radioValue, setRadioValue] = useState("product_store_grades");
	const [tablesList, setTablesList] = useState([]);
	const [columnsList, setColumnsList] = useState([]);
	const navigate = useNavigate();
	const defaultBehaviour = "default";
	useEffect(() => {
		if (props.tables) {
			setTablesList(props.tables);
		} else {
		}
		// if(props.isDynamicTable){
		// 	setColumnsList(getDynmaicColumns(tableConfig,props.apikey,props?.columProperties))
		// }
	}, [props]);

	const onAction = (action) => {
		if (action.actionName === "navigate") {
			navigate(
				"/" +
					window?.location?.pathname?.split("/")[1] +
					"/" +
					window?.location?.pathname?.split("/")[2] +
					action.path,
			);
		} else if (action.actionName === "tab-redirect") {
			if (action.path) {
				navigate(
					"/" +
						window?.location?.pathname?.split("/")[1] +
						"/" +
						window?.location?.pathname?.split("/")[2] +
						action.path,
				);
			}
			dispatch({
				type: "TAB_REDIRECT",
				payload: {
					activeTabIndex: action.activeTabIndex,
					parentTabIndex: action.parentTabIndex,
					activeTabValue: action.activeTabValue,
					parentTabValue: action.parentTabValue,
				},
			});
		} else {
			setShowChildTable(true);
		}
	};

	const handleRadioChange = (event, newValue) => {
		setRadioValue(newValue);
	};

	const selectLabel = (label) => {
		return (
			<>
				{label} <span style={{ color: "red" }}> * </span>
			</>
		);
	};

	const autoGroupColumnDef = (obj) => {
		return {
			
			headerValueGetter: (params) => `${obj.groupColumnName}`,
			minWidth: obj.groupColumnWidth || 220,
			pinned: obj.pinned,
			cellRendererParams: {
				suppressCount: obj.suppressCount || true,
				checkbox: obj.suppressRowGroupCheckbox ? false : true,
			},
		};
	};
	const groupRowsAutoGroupColumnDef = (obj) => {
		if(obj.valueGetterRequired){
			return {
				valueGetter: params => params.data[`${obj.valueGetterRequired}`],
				headerValueGetter: (params) => `${obj.groupColumnName}`,
				minWidth: 220,
				pinned: obj.pinned,
				cellRendererParams: {
					suppressCount: obj.suppressCount || false,
					checkbox: false,
				},
			};
		}
		return {
			headerValueGetter: (params) => `${obj.groupColumnName}`,
			minWidth: 220,
			pinned: obj.pinned,
			cellRendererParams: {
				suppressCount: obj.suppressCount || false,
				checkbox: obj.enableCheckbox || false,
			},
		};
	};

	return (
		<>
			{tablesList.map((obj, index) => (
				<>
					{(!obj.disabled || obj.key === enabledTable) && (
						<Grid
							key={index}
							className={
								"impact-vertical-table-container impact-vertical-table-container" +
								obj.key
							}
						>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								{obj?.title?<Typography variant="h6subtitle2">
									<h4>{obj.title}</h4>
								</Typography>:null}

								{obj.actionButton && (
									<>
										<CustomActionButton data={obj.actionButton} />
									</>
								)}
							</div>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								{obj.groupDisplayType ? (
									<>
										<Table
											apikey={obj.key}
											columnDefs={obj?.isDynamicTable ? getDynmaicColumns(tableConfig,obj.key,obj?.columProperties,pageConfigData) : obj.column } 
											createExtraColDefs={props.createExtraColDefs}
											floatingFilter={false}
											width={obj.width || null}
											height={obj.height || null}
											rowSelection={props.rowSelection || 'multiple'}
											groupDisplayType={"groupRows"}
											tableCssStyle={
												obj.tableCssStyle === undefined
													? defaultBehaviour
													: obj.tableCssStyle
											}
											autoGroupColumnDef={groupRowsAutoGroupColumnDef(obj)}
											filterColumns={obj.filterColumns}
											filterColumnId={obj.filterColumnId}
											onRowselectionChanged={(selectedRows, gridApi) => {
												dispatch({
													type: "DEPENDENT_COMPONENTS",
													payload: { [obj.actionKey]: !(selectedRows.length === 0)}
												})
												// if (obj.editDependence && selectedRows.length === 0) {
												// 	dispatch({
												// 		type: "EDIT_ACTION",
												// 		payload: { [obj.actionKey]: false }
												// 	})
												// }
											}}
											{...obj}
										/>
									</>
								) : (
									<>
										<Table
											apikey={obj.key}
											// columnDefs={obj.column}
											columnDefs={obj?.isDynamicTable ? getDynmaicColumns(tableConfig,(obj.key || obj.isOtherRequest),obj?.columProperties,pageConfigData) : obj.column } 
											createExtraColDefs={props.createExtraColDefs}
											floatingFilter={false}
											height={obj.height || null}
											width={obj.width || null}
											rowSelection={props.rowSelection || "multiple"}
											tableCssStyle={
												obj.tableCssStyle === undefined
													? defaultBehaviour
													: obj.tableCssStyle
											}
											autoGroupColumnDef={autoGroupColumnDef(obj)}
											filterColumns={obj.filterColumns}
												filterColumnId={obj.filterColumnId}
												onRowselectionChanged={(selectedRows, gridApi) => {
													dispatch({
														type: "DEPENDENT_COMPONENTS",
														payload: { [obj.actionKey]: !(selectedRows.length === 0)}
													})
													// if (obj.editDependence && selectedRows.length === 0) {
													// 	dispatch({
													// 		type: "EDIT_ACTION",
													// 		payload: { [obj.actionKey]: false }
													// 	})
													// }
												}}
											importStyle={obj?.importStyle?true:false}
											setNestedTableData={props.setNestedTableData}
                      childTableData={props?.childTableData}
											{...obj}
										/>
									</>
								)}
							</Grid>
							{obj.bottomActionButton && (
								<div style={{ textAlign: obj.bottomActionButton.alignment }}>
									{obj.bottomActionButton.details?.map((item) => (
										<Button
											style={{ textTransform: "none", margin: 5 }}
											variant={item.varient}
											size={item.size}
											// disabled={obj.disableFeat && diable}
											onClick={() => onAction(item.action)}
										>
											{item.name}
										</Button>
									))}
								</div>
							)}
							{obj.childTable && showChildTable && (
								<>
									{obj.childTable.map((nestedObj) => (
										<>
											{nestedObj.radioData ? (
												<>
													<div
														style={{
															color: "white",
															background: "#0055af",
															borderRadius: "3px",
															paddingLeft: "15px",
														}}
													>
														<RadioGroup
															row
															aria-labelledby="demo-row-radio-buttons-group-label"
															name="row-radio-buttons-group"
															onChange={handleRadioChange}
															value={radioValue}
														>
															{nestedObj.radioData.checks.map((item) => (
																<FormControlLabel
																	value={item.value}
																	control={
																		<Radio style={{ color: "white" }}> </Radio>
																	}
																	label={item.label}
																/>
															))}
														</RadioGroup>
													</div>
													<>
														<div
															style={{
																display: "flex",
																justifyContent: "space-between",
															}}
														>
															<Grid item md={3}>
																<div style={{ marginLeft: "4%" }}>
																	{nestedObj.radioData.textField?.map(
																		(field) => (
																			<TextFieldWithLabel
																				type={field.type}
																				label={selectLabel(field.label)}
																				width={field.width}
																			/>
																		),
																	)}
																</div>
															</Grid>
															{nestedObj.radioData[radioValue].actionButton && (
																<CustomActionButton
																	data={
																		nestedObj.radioData[radioValue].actionButton
																	}
																/>
															)}
														</div>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Table
																apikey={nestedObj.radioData[radioValue].key}
																createExtraColDefs={props.createExtraColDefs}
																columnDefs={
																	nestedObj.radioData[radioValue].column
																}
																tableCssStyle={
																	obj.tableCssStyle === undefined
																		? defaultBehaviour
																		: obj.tableCssStyle
																}
																floatingFilter={false}
																height={obj.height || null}
																rowSelection={props.rowSelection || "multiple"}
															/>
															{nestedObj.radioData[radioValue]
																.bottomActionButton && (
																<div
																	style={{
																		textAlign:
																			nestedObj.radioData[radioValue]
																				.bottomActionButton.alignment,
																	}}
																>
																	{nestedObj.radioData[
																		radioValue
																	].bottomActionButton.details?.map((item) => (
																		<Button
																			style={{
																				textTransform: "none",
																				margin: 5,
																			}}
																			variant={item.varient}
																			size={item.size}
																			// disabled={obj.disableFeat && diable}
																			onClick={() => onAction(item.action)}
																		>
																			{item.name}
																		</Button>
																	))}
																</div>
															)}
														</Grid>
													</>
												</>
											) : (
												<>
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}
													>
														<Typography variant="h6subtitle2">
															{nestedObj.title}
														</Typography>
														{nestedObj.actionButton && (
															<>
																<CustomActionButton
																	data={nestedObj.actionButton}
																/>
															</>
														)}
													</div>
													<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
														<Table
															apikey={nestedObj.key}
															createExtraColDefs={props.createExtraColDefs}
															// columnDefs={nestedObj.column}
															columnDefs={nestedObj?.isDynamicTable ? getDynmaicColumns(tableConfig,nestedObj.apikey,nestedObj?.columProperties,pageConfigData) : nestedObj.column }
															floatingFilter={false}
															tableCssStyle={
																obj.tableCssStyle === undefined
																	? defaultBehaviour
																	: obj.tableCssStyle
															}
															rowSelection={props.rowSelection || "multiple"}
															height={obj.height || null}
															{...nestedObj}
														/>
													</Grid>
												</>
											)}
										</>
									))}
								</>
							)}
							<div className="spacer"></div>
						</Grid>
					)}
				</>
			))}
		</>
	);
};
export default VerticleTables;
