import { useEffect } from "react";
export default function CellGroupSet(params) {
  const groupSetId = params.node.group ? params.node.allLeafChildren[0].data["id"] : params.data.id
  useEffect(() => {
    const set = new Set();
    params.api.forEachLeafNode((node) => {
      if (node.data["#group_id"] === groupSetId) {
        set.add(node.data[params.colDef.field]);
      }
    });
    params.setValue(Array.from(set).join(","));
  }, [params]);
  return <div>{params.value}</div>;
}
