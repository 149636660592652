export const heatmapOptions = (props) => {
  const options = {
    chart: {
      type: "heatmap",
      marginTop: 40,
      marginBottom: 80,
      plotBorderWidth: 1,
    },
    title: {
      text: props.title,
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      categories: props.xaxis.categories,
      labels: {
        rotation: -20,
        x: 15,
        style: {
          fontSize: "10px",
          fontFamily: "Poppins, sans-serif",
        },
        formatter: function () {
          return this.value.toString().substring(0, 10);
        },
      },
      title: {
        text: props.xaxis.title,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          fontFamily: "Poppins, sans-serif",
        },
      },
    },

    yAxis: {
      categories: props.yaxis.categories,
      title: {
        text: props.yaxis.title,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          fontFamily: "Poppins, sans-serif",
        },
      },
      reversed: true,
      labels: {
        rotation: 0,
        style: {
          fontSize: "10px",
          fontFamily: "Poppins, sans-serif",
        },
        formatter: function () {
          return this.value.toString().substring(0, 10);
        },
      },
    },
    //   plotOptions: {
    //     heatmap: {
    //         point: {
    //             events: {
    //                 click: (pt)=> {
    //                     alert(`Point was clicked!,${pt}`);
    //                     <Redirect to='/deep-dive'/>
    //                 }
    //             }
    //         }
    //     }
    // },

    accessibility: {
      point: {
        descriptionFormatter: function (point) {
          var ix = point.index + 1,
            xName = point.x,
            yName = point.y,
            val = point.value;
          return ix + ". " + xName + " sales " + yName + ", " + val + ".";
        },
      },
    },

    colorAxis: {
      // min: 0,
      // minColor: "#FFFFFF",
      // maxColor: Highcharts.getOptions().colors[0],
      min: props.colorAxis.min || 6,
      max: props.colorAxis.max || 15,
      // minColor: "red",
      // maxColor: 'green',
      stops: props.stops || [
        [0, "#8B0000"],
        [0.1, "#B22222"],
        [0.2, "#DC143C"],
        [0.3, "#FF0000"],
        [0.4, "#FF6347"],
        [0.5, "#FFCCCB"],
        [0.6, "#90ee90"],
        [0.7, "#90ee90"],
        [0.8, "#65a765"],
        [0.9, "green"],
        [1, "darkgreen"],
      ],
      labels: {
        formatter: function () {
          return props.colorAxisLabelFormats &&
            Object.keys(props.colorAxisLabelFormats).length > 0
            ? `${props.colorAxisLabelFormats[this.value]}`
            : `${this.value}`;
        },
        // format:
      },
    },

    legend: {
      enabled: !props.colorBarDisabled,
      align: "right",
      layout: "vertical",
      margin: 10,
      verticalAlign: "top",
      y: 25,
      symbolHeight: 280,
    },

    tooltip: {
      formatter: function () {
        return (
          ` <b>${props.xAxisLabel}: ` + this.point,
          "x" + `</br> ${props.yAxisLabel}: <b>` + this.point,
          "y"
        );
      },
    },

    series: props.data,
    plotOptions: {
      series: {
        borderWidth: props.plotOptions?.borderWidth,
        borderColor: props.plotOptions?.borderColor,
        dataLabels: {
          color: "black",
          enabled: props.plotOptions?.dataLabels.enabled,
          formatter: function () {
            return (
              props.plotOptions?.dataLabels.text + this.point.value ||
              this.point.value
            );
          },
        },
        keys: ["x", "y", "value", "color"],
      },
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {
                formatter: function () {
                  return this.value.charAt(0);
                },
              },
            },
          },
        },
      ],
    },
  };

  return options;
};
