import { useEffect, useState } from "react";
import VerticleTables from "./verticleTables";

const NestedTable = (props) => {
  const { nestedTableConfig } = props;
  const [childTableData, setChildTableData] = useState([]);
  const [nestedTableData, setNestedTableData] = useState(false);
  const [isDataPopulated, setIsDatapopulated] = useState(false);
  const [cellStyle, setCellStyle] = useState({ color: "black" });
  // Prop Drilling for child table data
  useEffect(() => {
    if (nestedTableData) {
      setChildTableData(
        nestedTableData.filter((item) => {
          setIsDatapopulated(true);
          return props.data.id === item.mapping_id;
        })
      );
    }
  }, [nestedTableData]);

  //Color Cell Renderer for table
  useEffect(() => {
    // cellStyleChecker(props.data[nestedTableConfig.info[0].parentFieldName]);
    if (props.cellStyleChecker) {
      let color = props.cellStyleChecker.values.find(
        ({ element }) =>
          props.data[nestedTableConfig.info[0].parentFieldName] === element
      )?.color;
      setCellStyle({ color: "black", background: color });
    }
  }, [props?.cellStyleChecker]);
  return (
    <>
      {!isDataPopulated ? (
        <div className="nested-table">
          <VerticleTables
            tables={nestedTableConfig.info}
            setNestedTableData={setNestedTableData}
            childTableData={childTableData.length > 0 && childTableData}
          />
        </div>
      ) : (
        <>
          {childTableData.length > 0 ? (
            <div className="nested-table">
              <VerticleTables
                tables={nestedTableConfig.info}
                setNestedTableData={setNestedTableData}
                childTableData={childTableData.length > 0 && childTableData}
              />
            </div>
          ) : (
            <>
              {props.data[nestedTableConfig.info[0].parentFieldName] !==
              null ? (
                <p style={{ width: "100%", paddingLeft: 5, ...cellStyle }}>
                  {props.data[nestedTableConfig.info[0].parentFieldName]}
                </p>
              ) : (
                ""
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default NestedTable;
