import React, {
	useState,
	useCallback,
	useMemo,
	useRef,
	useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./Table.scss";
import { theme } from "../../theme";
import {
	getColumnDefs,
	currencyFormatter,
	percentageFormatter,
	NoFormatter,
	defaultCellStyle,
} from "./utils";
import { LicenseManager } from "ag-grid-enterprise";
import { fetchData, getMultipleModelsData } from "../../pages/Home/action";
import { CustomRendererComponent } from "../../pages/Home/CustomComponents/CustomRendererComponent";
import { useDispatch, useSelector } from "react-redux";
import { EditWeekData } from "../../pages/Home/CustomComponents/editWeekData";
import AddHideMetrics from "../../pages/Home/CustomComponents/ShowHideMetrics";
import { EditTextField } from "../../pages/Home/CustomComponents/editTextFields";
import CellTextField from "../../pages/Home/CustomComponents/CellTextField";
import CellImageField from "../../pages/Home/CustomComponents/CellImageField";
import CellPopOverImageField from "../../pages/Home/CustomComponents/CellPopOverImageField";
import DropdownCellRenderer from "../../pages/Home/CustomComponents/dropdownCellRender";
import CellSwitchField from "../../pages/Home/CustomComponents/CellSwitchField";
import CellInfoIcon from "../../pages/Home/CustomComponents/CellInfoIcon";
import { PreformattedText } from "../../pages/Home/CustomComponents/PreformattedText";
import CellAddIcon from "../../pages/Home/CustomComponents/CellAddIcon";
import CellMultiSelectDropdown from "../../pages/Home/CustomComponents/CellMultiSelectDropdown";
import CellChips from "../../pages/Home/CustomComponents/CellChips";
import CircularColorChips from "../../pages/Home/CustomComponents/CircularColorChips";
import CellColor from "../../pages/Home/CustomComponents/CellColor";
import ColumnColorRange from "../../pages/Home/CustomComponents/ColumnColorRange";
import CellPopOverAction from "../../pages/Home/CustomComponents/CellPopOverAction";
import _ from "lodash";
import CellMultipleSelect from "../../pages/Home/CustomComponents/CellMultipleSelect";
import CellDropdownMap from "../../pages/Home/CustomComponents/CellDropdownMap";
import CellDropdownMulti from "../../pages/Home/CustomComponents/CellDropdownMulti";
import CustomMultiComponentCell from "../../pages/Home/CustomComponents/CustomMultiComponentCell";
import useAction from "../../utils/useAction";
import tableActionsToolPanel from "./tableActionsToolPanel";
import CellGroupSet from "../../pages/Home/CustomComponents/CellGroupSet";
import CellRendererSelector from "../../pages/Home/CustomComponents/CellRendererSelector";
import CellCompute from "../../pages/Home/CustomComponents/CellCompute";
import GroupCellRenderer from "../../pages/Home/CustomComponents/GroupCellRenderer";
import TooltipComponent from "../../pages/Home/CustomComponents/TooltipComponent";
import CellToggle from "../../pages/Home/CustomComponents/CellToggle";
import CellDateRange from "../../pages/Home/CustomComponents/CellDateRange";
import NestedTable  from "../../pages/Home/CustomComponents/NestedTable";
const LICENSE_KEY =
	"CompanyName=Impact Analytics,LicensedGroup=31Jan22 Purchase,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-025014,ExpiryDate=31_January_2023_[v2]_MTY3NTEyMzIwMDAwMA==e4f58ef1fe10261cf66aa1e5a5cb2da6";
LicenseManager.setLicenseKey(LICENSE_KEY);

const Table = (props) => {
	const {
		headerHeight,
		rowHeight,
		createExtraColDefs,
		filterColumns,
		filterColumnId,
		autoHeight,
		filterColumn,
	} = props;
	const dispatch = useDispatch();
	const {
		deleteTableRow,
		isExportTable,
		selectedRowInfo,
		tableInfo,
		columnsInfo,
		dependentInfo,
		tableValues,
		dropdownSelectionData,
		editActionInfo,
		tableInfoSaved,
		formEditedValues,
		filtersDependentInfo,
	} = useSelector((state) => state.home);
	const { formMasterValuesData, tableConfig } = useSelector(
		(state) => state.filter
	);
	const invokeAction = useAction();
	const gridRef = useRef();
	const [defaultData, setDefaultData] = useState(null);
	const [modal, setModal] = useState(false);
	const [columnDefs, setColumnDefs] = useState([props.columnDefs]);
	const [defaultColDef, setDefaultColDef] = useState(() => ({
		enableValue: true,
		enableRowGroup: true,
		enablePivot: true,
		sortable: true,
		filter: "agTextColumnFilter",
		editable: props.editable,
		resizable: true,
		minWidth: 100,
		autoHeight: props.autoHeight,
		wrapHeaderText: true,
		autoHeaderHeight: true,
		cellStyle: (params) => {
			return defaultCellStyle(params, props);
		},
	}));
	const [refresh, setRefresh] = useState(false);
	const [headerMap, setHeaderMap] = useState({});
	const [fHeaderMap, setFHeaderMap] = useState(null);
	const [columnsToFilter, setColumnsToFilter] = useState([]);
	const [firstRefresh, setFirstRefresh] = useState(true);
	const [gridOptions, setGridOptions] = useState({
		rememberGroupStateWhenNewData: true,
		suppressScrollOnNewData: true,
		domLayout: (props?.sizeToRows && "autoHeight") || "normal",
	});
	const [context, setContext] = useState({
		editedColumns: [],
	});
	const [rowData, setRowData] = useState([]);
	const [gridApi, setGridApi] = useState(null);
	const [gridColumnApi, setGridColumnApi] = useState({});
	const RefreshTable = () => setRefresh((prev) => !prev);
	useEffect(() => {
		const { apikey, isApiNotRequird } = props;
		if (apikey && !isApiNotRequird) {
			let reqParams = [apikey];
			let payload = {};
			if (
				tableInfo[`${apikey}`] &&
				tableInfo[`${apikey}`].length > 0 &&
				props?.callNotRequiredIfDataExist
			) {
				if (props?.dynamicTable) {
					setRowData([...tableInfo[`${apikey}_dynamic`]] || []);
				} else setRowData([...tableInfo[apikey]]);
				if (props.isStoreRequired) {
					if (dependentInfo[`${props.dependentOnTab}`]) {
						let params = {};
						if (props.replaceOtherApi) {
							params[`${props.source}`] = [
								...tableInfo[`${props.replaceOtherApi}`],
							];
							dispatch({
								type: "TABLE_DATA",
								payload: params,
							});
						}
					}
				}
			} else if (props?.isSaveRequired && tableInfoSaved[apikey]) {
				setRowData([...tableInfoSaved[apikey]]);
				dispatch({
					type: "TABLE_DATA",
					payload: { [apikey]: _.cloneDeep(tableInfoSaved[apikey]) },
				});
			} else {
				// else if (!Object.keys(tableValues).includes(apikey)) {
				getMultipleModelsData({ model_names: reqParams }).then((res) => {
					setDefaultData([...res[apikey]]);
					setRowData([...res[apikey]]);
					payload[apikey] = [...res[apikey]];
					// payload[`${apikey}_copy`] = [...res[apikey]];
					// dispatch({
					// 	type: "TABLE_VALUES",
					// 	payload: payload,
					// });
					if (props.isStoreRequired) {
						let params = {};
						params[`${apikey}`] = [...res[apikey]];
						if (dependentInfo[`${props.dependentOnTab}`]) {
							if (
								props.replaceOtherApi &&
								tableInfo[`${props.replaceOtherApi}`]
							) {
								params[`${props.source}`] = [
									...tableInfo[`${props.replaceOtherApi}`],
								];
							}
						}
						if (
							dependentInfo[`${props.default_table_data}`] &&
							props.default_table_data
						) {
							params[`${props.source}`] = [
								...tableInfo[`${props.default_table_data}`],
							];
						}

						if (props.isCopyRequired) {
							params[`${apikey}_copy`] = [...res[apikey]];
						}

						if (props.storeDataBasedoOnKey) {
							if (Object.keys(props.storeDataBasedoOnKey).length > 0) {
								const basedOnKeyData = res[apikey].filter((obj) => {
									if (
										obj[`${props.storeDataBasedoOnKey.key}`] ===
										props.storeDataBasedoOnKey.value
									) {
										return obj;
									}
								});
								params[`${apikey}`] = basedOnKeyData;
							}
						}
						if (props?.dynamicTable) {
							params[`${apikey}_dynamic`] = [];
						}
						dispatch({
							type: "TABLE_DATA",
							payload: params,
						});
					}
				});

				if (props.otherApiKeys) {
					setTimeout(() => {
						getMultipleModelsData({ model_names: props.otherApiKeys }).then(
							(res) => {
								if (props.isStoreRequired) {
									let params = {};
									if (props.otherApiKeys) {
										props.otherApiKeys.forEach((apiObj) => {
											params[`${apiObj}`] = [...res[apiObj]];
										});
									}
									dispatch({
										type: "TABLE_DATA",
										payload: params,
									});
								}
							}
						);
					}, 1000);
				}
				if (props.isColumnStoreRequired) {
					let columnParams = {};
					columnParams[`${apikey}`] = props.columnDefs;
					if (props.isCopyRequired) {
						columnParams[`${apikey}_copy`] = props.columnDefs;
					}
					if (props.addColumnsList) {
						columnParams[`${apikey}_add`] = props.addColumnsList;
					}
					dispatch({
						type: "COLUMN_DATA",
						payload: columnParams,
					});
				}

				if (props.showHideMetrics) {
					setGridOptions({
						...gridOptions,
						sideBar: sideBar,
					});
				}
			}
			// else {
			// 	console.log("-------------")
			// 	setRowData(tableValues[apikey]);
			// }
		}
	}, [props.apikey]);
	useEffect(()=>{
		if(props.isNestedTable){
			if(rowData.length>0){
				props.setNestedTableData(rowData)
			}
		}
	},[rowData])

	//on unmount
	useEffect(() => {
		return () => {
			invokeAction(props?.actionOnUnmount);
			dispatch({
				type: "DEPENDENT_COMPONENTS",
				payload: { [props?.actionKey || props?.apikey]: null },
			});
		};
	}, []);

	const isRedrawRows = (gridRef, props, formEditedValues) => {
		return gridRef &&
			gridRef.current &&
			gridRef.current.api &&
			(!props.isRedrawRowsNotRequired || formEditedValues[`isRefreshCells`])
	};
	useEffect(() => {
		if (tableInfo[`${props.apikey}`]) {
			if (props?.dynamicTable) {
				setRowData([...tableInfo[`${props.apikey}_dynamic`]] || []);
				// gridRef.current.api.redrawRows();
			} else {
				setRowData([...tableInfo[`${props.apikey}`]]);
			}
			if (isRedrawRows(gridRef, props, formEditedValues)) {
				if (props.isClickBasedOnParent) {
					// gridRef.current.api.refreshCells({ force: true })
				} else {
					// gridRef.current.api.redrawRows();
					setTimeout(() => gridRef?.current?.api.redrawRows(), 0)
					if (props.childGroupDefaultOpenOptions) {
						setTimeout(() => {
							expandChildGroup();
						}, 5);
					}
				}
				// gridRef.current.api.onFilterChanged();
				setTimeout(() => gridRef.current.api.onFilterChanged(), 0)
			}
			if (props.showHideMetrics) {
				setGridOptions({
					...gridOptions,
					sideBar: sideBar,
				});
			}
			if (formEditedValues[`isRefreshCells`]) {
				dispatch({
					type: "FORM_EDITED_VALUES",
					payload: {
						isRefreshCells: false,
					},
				});
			}
		}
	}, [tableInfo]);

	useEffect(() => {
		if (props?.isClickBasedOnParent && gridApi) {
			if (firstRefresh) {
				gridApi.setRowData([...rowData]);
				setFirstRefresh(false);
			} else {
				gridApi.refreshCells({ force: true });
			}
		}
	}, [rowData]);

	const filterTable = useCallback(
		(filter_data, sourceData, tableToFilter) => {
			const idSet = new Set();
			sourceData.forEach((row) => {
				if (
					Object.keys(filter_data).every(
						(key) =>
							filter_data[key].size == 0 ||
							filter_data[key].has(row[fHeaderMap[key]])
					)
				) {
					idSet.add(row.id);
				}
			});
			return tableToFilter.filter((row) => idSet.has(row.id));
		},
		[fHeaderMap]
	);

	useEffect(() => {
		if (props?.filtering) {
			const filterData = filtersDependentInfo[props.filtering.filterId];
			if (filterData && defaultData && defaultData.length > 0) {
				const filteredRows = filterTable(
					filterData,
					defaultData,
					tableInfo[props.apikey]
				);
				dispatch({
					type: "TABLE_DATA",
					payload: { [props.apikey]: filteredRows },
				});
			}
		}
	}, [filtersDependentInfo]);

	useEffect(() => {
		if (columnsInfo[`${props.apikey}`]) {
			setColumnDefs([
				...getColumnDefs(
					columnsInfo[`${props.apikey}`],
					props.isValueFormatterRequired,
					props.importStyle,
					dispatch
				),
			]);
			if (gridRef && gridRef.current && gridRef.current.api) {
				gridRef.current.api.setColumnDefs(columnDefs);
				gridApi.sizeColumnsToFit();
			}
		}
	}, [columnsInfo]);

	useEffect(() => {
		if (props.columnDefs.length > 0) {
			setColumnDefs(
				getColumnDefs(
					props.columnDefs,
					props.isValueFormatterRequired,
					props.importStyle,
					dispatch
				)
			);
		}
		setColumnDefs(props.columnDefs);
	}, [props.columnDefs]);

	useEffect(() => {
		if (props.isOtherRequest) {
			let payload = {};
			fetchData(props.isOtherRequest).then((res) => {
				setRowData(res);
				payload[props.isOtherRequest] = [...res];
				dispatch({
					type: "TABLE_DATA",
					payload: payload,
				});
			});
		}
	}, [props.isOtherRequest]);

	const headerHeightGetter = () => {
		var columnHeaderTexts = [
			...document.querySelectorAll(".ag-header-cell-text"),
		];
		var clientHeights = columnHeaderTexts.map(
			(headerText) => headerText.clientHeight
		);
		var tallestHeaderTextHeight = Math.max(...clientHeights);

		return tallestHeaderTextHeight;
	};

	const groupHeaderHeightGetter = () => {
		//ag-header-group-cell-label
		var columnHeaderTexts = [
			...document.querySelectorAll(".ag-header-group-cell-label"),
		];

		var clientHeights = columnHeaderTexts.map(
			(headerText) => headerText.clientHeight
		);
		var tallestHeaderTextHeight = Math.max(...clientHeights);

		return tallestHeaderTextHeight;
	};

	const onFirstDataRendered = (params) => {
		setGridApi(params.api);
		gridColumnApi.setColumnsVisible(
			columnsToFilter,
			props.filterDefaultAllVisible
		);
		//to load the default table style
		if (
			props.tableCssStyle === "default" ||
			props.tableCssStyle === undefined
		) {
			var allColumnIds = [];
			gridColumnApi.getAllColumns().forEach(function (column) {
				allColumnIds.push(column.colId);
			});
			gridColumnApi.autoSizeColumns(allColumnIds, "skipHeader");
		}

		if (props.tableCssStyle === "sizeColumnsToFit") {
			if (Object.keys(gridApi).length > 0) {
				gridApi.sizeColumnsToFit();
			}
		}
		if (props.tableCssStyle === "autoSizeAllColumns") {
			if (Object.keys(gridColumnApi).length > 0) {
				gridColumnApi.autoSizeAllColumns();
			}
		}
		if (props.isDefaultCheckBoxSelection) {
			params.api.forEachNode((node) =>
				node.setSelected(
					!!node.data &&
						props.isDefaultCheckBoxSelection.indexOf(
							node.data[`${props.defaultCheckBasedOn}`]
						) !== -1
				)
			);
		}
		var padding = 10;
		var height = headerHeightGetter() + (props?.headerPadding || padding);
		var groupHeaderHeight = groupHeaderHeightGetter() - 10;

		params.api.setHeaderHeight(height);
		params.api.setGroupHeaderHeight(groupHeaderHeight);
		params.api.resetRowHeights();
		params.api.onFilterChanged();
		if (props?.isSaveRequired) {
			params.api.setRowData([...tableInfo[props.apikey]]);
		}
		if (props?.actionOnFirstRender) {
			invokeAction(props.actionOnFirstRender);
		}
		if (props?.filtering) {
			const filterData = filtersDependentInfo[props.filtering.filterId];
			if (filterData && defaultData && defaultData.length > 0) {
				const filteredRows = filterTable(
					filterData,
					defaultData,
					tableInfo[props.apikey]
				);
				dispatch({
					type: "TABLE_DATA",
					payload: { [props.apikey]: filteredRows },
				});
			}
		}
		RefreshTable();
	};
	const onRowDataChanged = (params) => {
		if (props?.actionOnInit) {
			invokeAction(props.actionOnInit);
		}
	};
	// Export grid to excel
	useEffect(() => {
		// if (isExportTable) {
		// 	gridRef.current.api.exportDataAsExcel({
		// 		onlySelected: selectedRowInfo[props?.apikey].length > 0,
		// 	});
		// 	dispatch({
		// 		type: "EXPORT_TO_EXCEL",
		// 		payload: false,
		// 	});
		// }
		if (isExportTable) {
			if (
				Object.keys(selectedRowInfo).length &&
				selectedRowInfo[props?.apikey].length > 0
			) {
				gridRef.current.api.exportDataAsExcel({
					onlySelected: selectedRowInfo[props?.apikey].length > 0,
				});
			} else {
				gridRef.current.api.exportDataAsExcel();
			}
			dispatch({
				type: "EXPORT_TO_EXCEL",
				payload: false,
			});
		}
	}, [isExportTable]);

	// Delete grid rows
	useEffect(() => {
		if (deleteTableRow) {
			const selectedRowData = gridRef.current.api.getSelectedRows();
			gridRef.current.api.applyTransaction({ remove: selectedRowData });
			dispatch({
				type: "DELETE_SELECTED_TABLE_ROW",
				payload: false,
			});
		}
	}, [deleteTableRow]);

	// useEffect(() => {
	//   let hiddenColumnsArray = filterColumns;
	//   if (
	//     dropdownSelectionData !== undefined &&
	//     dropdownSelectionData[filterColumnId] !== undefined &&
	//     gridColumnApi.setColumnsVisible !== undefined
	//   ) {
	//     //column filter
	//     let columnsArray = [];
	//     dropdownSelectionData[filterColumnId]?.map((value) => {
	//       columnsArray.push(value.value);
	//     });
	//     gridColumnApi.setColumnsVisible(columnsArray, true);
	//     let array3 = hiddenColumnsArray?.filter(
	//       (val) => !columnsArray.includes(val)
	//     );
	//     gridColumnApi.setColumnsVisible(array3, false);
	//     //row filter
	//     if (props.rowFilter) {
	//       const rowArray = dropdownSelectionData[filterColumnId]?.map(
	//         ({ value }) => value
	//       );
	//       console.log(rowArray)
	//       setRowData((prev) => {
	//         console.log(tableInfo[props.apikey])

	//         const filteredRows = tableInfo[props.apikey]?.filter((row) => rowArray.includes(row[filterColumn]));
	//           console.log(filteredRows)
	//           return [...filteredRows]
	//       });
	//     }
	//   }
	// }, [dropdownSelectionData]);

	const setupColumnFilters = (columnDefs) => {
		const _fHeaderMap = {};
		function extractFields(columns, arr) {
			columns.forEach((col) => {
				if (col?.children) extractFields(col.children, arr);
				else {
					arr.push(col.field);
					_fHeaderMap[col?.fHeader || col.headerName] = col.field;
				}
			});
		}
		const _headerMap = {};
		columnDefs.forEach((col) => {
			const arr = [];
			if (col?.children) extractFields(col.children, arr);
			else {
				arr.push(col.field);
				_fHeaderMap[col?.fHeader || col.headerName] = col.field;
			}
			_headerMap[col?.identifier || col.headerName] = arr;
		});
		setHeaderMap(_headerMap);
		setFHeaderMap(_fHeaderMap);
		setColumnsToFilter((prev) => {
			const obj = [];
			filterColumns?.forEach((header) =>
				_headerMap[header]?.forEach((field) => obj.push(field))
			);
			return obj;
		});
	};

	// Export grid to excel
	// useEffect(() => {
	// 	if (isExportTable) {
	// 		gridRef.current.api.exportDataAsExcel({
	// 			onlySelected: selectedRowInfo[props?.apikey].length > 0,
	// 		});
	// 		dispatch({
	// 			type: "EXPORT_TO_EXCEL",
	// 			payload: false,
	// 		});
	// 	}
	// }, [isExportTable]);

	// Delete grid rows
	useEffect(() => {
		if (deleteTableRow) {
			const selectedRowData = gridRef.current.api.getSelectedRows();
			gridRef.current.api.applyTransaction({ remove: selectedRowData });
			dispatch({
				type: "DELETE_SELECTED_TABLE_ROW",
				payload: false,
			});
		}
	}, [deleteTableRow]);
	useEffect(() => {
		let hiddenColumnsArray = columnsToFilter;
		if (
			dropdownSelectionData !== undefined &&
			dropdownSelectionData[filterColumnId] !== undefined &&
			gridColumnApi.setColumnsVisible !== undefined
		) {
			//column filter
			let columnsArray = [];
			dropdownSelectionData[filterColumnId]?.forEach(({ value }) => {
				headerMap[value]?.forEach((field) => columnsArray.push(field));
			});
			gridColumnApi.setColumnsVisible(columnsArray, true);
			let array3 = hiddenColumnsArray?.filter(
				(val) => !columnsArray.includes(val)
			);
			gridColumnApi.setColumnsVisible(array3, false);
			//row filter
		}
		if (props?.rowFilter) {
			if (dropdownSelectionData && dropdownSelectionData[filterColumnId]) {
				const rowArray = dropdownSelectionData[filterColumnId]?.map(
					({ value }) => value
				);
				setRowData((prev) => {
					const filteredRows = tableInfo[props.apikey]?.filter((row) =>
						rowArray.includes(row[filterColumn])
					);
					return [...filteredRows];
				});
			}
		}
	}, [dropdownSelectionData, refresh]);

	const isImagePresent = () => {
		const imageColumn = props.columnDefs.find((column) => {
			return column?.cellRenderer === "childMessageRenderer";
		});

		if (imageColumn) {
			return true;
		}

		return false;
	};

	const getRowStyle = (params) => {
		if (params.data && params.data.is_finalized) {
			return { background: theme.palette.bgTableFinalized };
		} else if (params.data && params.data.is_product_performance) {
			return {
				background:
					params.data.status === "track"
						? theme.palette.bgSuccessTransparent
						: params.data.status === "over"
						? theme.palette.bgWarningTransparent
						: theme.palette.bgDangerTransparent,
			};
		} else if (props.rowColor) {
			if (params.node.rowIndex === props.rowColor.index) {
				return { background: props.rowColor.color };
			}
		}

		return {
			background: props.bgtbcolor ? props.bgtbcolor : theme.palette.textWhite,
		};
	};
	const onGridReady = async (params) => {
		setGridApi(params.api);
		setGridColumnApi(params.columnApi);
		setupColumnFilters(params.columnApi.columnModel.columnDefs);
		dispatch({
			type: "SET_GRID_REF",
			payload: { [props.actionKey]: gridRef },
		});
	};

	const cellValueChanged = async (params) => {
		if (params.newValue !== params.oldValue) {
			const updatedData = [];
			updatedData.push({
				rowIndex: params.rowIndex,
				colId: params.column.colId,
			});
			setContext({
				// ...context,
				editedColumns: [...context.editedColumns].concat(updatedData),
			});

			if (props.isMultiplyApplyToRow) {
				let mult = 0;
				gridRef.current.api.forEachNode((node) => {
					if (params.rowIndex === node.rowIndex) {
					} else if (node.rowIndex === props.isMultiplyApplyToRow) {
						let updatedValue = Math.round(
							params.newValue * mult.replace(/\,/g, "")
						);
						let paramsReq = {};
						node.setDataValue(
							params.colDef.field,
							NoFormatter({ value: updatedValue })
						);

						if (props.updateToOtherTables) {
							// props.updateToOtherTables.forEach(tableObj=>{
							let tableObj = props.updateToOtherTables[0];
							const tableData = tableInfo[`${tableObj}`];
							// let colName = params.colDef.field.split("week_")
							//     // console.log("*******",node.data[`${params.colDef.field}`])
							//     // dataObj[`W ${colName[1]}`] = node.data[`${params.colDef.field}`]
							//     // dataObj.data[`${colName[1] -1 }`] = parseInt(Math.round(node.data[`${params.colDef.field}`].replace(/\,/g,'')))
							// tableData[3][`W ${colName[1]}`] = node.data[`${params.colDef.field}`]
							// tableData[3]['data'][`${colName[1] -1 }`] = parseInt(Math.round(node.data[`${params.colDef.field}`].replace(/\,/g,'')))
							tableData.filter((dataObj, index) => {
								if (dataObj["id"] == 4) {
									let colName = params.colDef.field.split("week_");
									dataObj[`W ${colName[1]}`] =
										node.data[`${params.colDef.field}`];
									dataObj.data[`${colName[1] - 1}`] = parseInt(
										Math.round(
											node.data[`${params.colDef.field}`].replace(/\,/g, "")
										)
									);
								}
							});
							paramsReq[`${tableObj}`] = tableData;
							// })

							dispatch({
								type: "TABLE_DATA",
								payload: paramsReq,
							});
						}
					} else {
						mult = node.data[`${params.colDef.field}`];
					}
				});
			}
			if (props.isDifferenceApplyToRow) {
				let mult = 0;
				gridRef.current.api.forEachNode((node) => {
					if (params.rowIndex === node.rowIndex) {
					} else if (node.rowIndex === props.isDifferenceApplyToRow) {
						let updatedValue = Math.round(
							mult - params.newValue.replace(/\,/g, "")
						);
						let paramsReq = {};
						node.setDataValue(
							params.colDef.field,
							NoFormatter({ value: updatedValue })
						);

						if (props.updateToOtherTables) {
							let tableObj = props.updateToOtherTables[0];
							const tableData = tableInfo[`${tableObj}`];
							tableData.filter((dataObj, index) => {
								if (dataObj["id"] == 6) {
									let colName = params.colDef.field.split("week_");
									dataObj[`W ${colName[1]}`] =
										node.data[`${params.colDef.field}`];
									dataObj.data[`${colName[1] - 1}`] = parseInt(
										Math.round(
											node.data[`${params.colDef.field}`].replace(/\,/g, "")
										)
									);
								}
							});
							paramsReq[`${tableObj}`] = tableData;

							dispatch({
								type: "TABLE_DATA",
								payload: paramsReq,
							});
						}
					} else {
						mult = node.data[`${params.colDef.field}`];
					}
				});
			}
			if (
				props.updateToOtherTable &&
				Object.keys(props.updateToOtherTable).length > 0
			) {
				if (
					params.data &&
					params.data[`${props.updateToOtherTable.rowKey}`] ===
						props.updateToOtherTable.rowValue
				) {
					const updateData = tableInfo[
						`${props.updateToOtherTable.parent_table_key}`
					].filter((obj) => {
						if (
							obj[`${props.updateToOtherTable.updatedRowKey}`] ===
							props.updateToOtherTable.updatedRowValue
						) {
							obj[`${params.colDef.field}`] = params.newValue.replace(
								/\,/g,
								""
							);
						}
						return obj;
					});
					let paramsReq = {};
					paramsReq[`${props.updateToOtherTable.parent_table_key}`] = [
						...updateData,
					];
					dispatch({
						type: "TABLE_DATA",
						payload: paramsReq,
					});
					if (props.updateToOtherTable.apiKeys) {
						dispatch({
							type: "FORM_EDITED_VALUES",
							payload: {
								isGraphDataUupdated: true,
								graphApiKeys: props.updateToOtherTable.apiKeys,
							},
						});
					}
				}
			}
			if (props["onCellEditedColumnKey"]) {
				let payloadReq = {};
				payloadReq[`${props["onCellEditedColumnKey"]}`] = params.newValue;
				dispatch({
					type: "FORM_EDITED_VALUES",
					payload: payloadReq,
				});
			}
			gridRef.current.api.refreshCells();
		}
	};

	const onCellClicked = async (params) => {
		if (props.clickChangeValue) {
			params.data[props.clickChangeField] = props.clickChangeValue;
		}
		gridRef.current.api.refreshCells();
	};

	const onSelectionChanged = (event) => {
		const selectedOptions = gridApi.getSelectedRows();
		if (props.isStoreRequired || props.isRowSelectionRequired) {
			let params = {};
			if (props.isOtherRequest) {
				params[`${props.isOtherRequest}`] = [...selectedOptions];
			} else {
				params[`${props.apikey}`] = [...selectedOptions];
			}
			dispatch({
				type: "SELECTED_ROWS",
				payload: params,
			});
		}
		if (props.onRowselectionChanged) {
			props.onRowselectionChanged(selectedOptions, gridApi);
		}
		let params = {};
		if (props.selectedRowsKey) {
			params[`${props.selectedRowsKey}`] = selectedOptions;
		}
		dispatch({
			type: "CHECKED_ROWS",
			payload: params,
		});
		params[`oddSelectionChange`] = !dependentInfo[`oddSelectionChange`];
		params[`evenSelectionChange`] = !dependentInfo[`evenSelectionChange`];
		dispatch({
			type: "DEPENDENT_COMPONENTS",
			payload: params,
		});
	};
	const sideBar = useMemo(() => {
		let toolPanel = [
			{
				id: "columns",
				labelDefault: "Columns",
				labelKey: "columns",
				iconKey: "columns",
				toolPanel: "agColumnsToolPanel",
			},
			{
				id: "filters",
				labelDefault: "Filters",
				labelKey: "filters",
				iconKey: "filter",
				toolPanel: "agFiltersToolPanel",
			},
			{
				id: "show_hide_metrics",
				labelDefault: props.subRowHideOnAllRows
					? "Show/Hide Rows"
					: "Show/Hide Metrics",
				labelKey: "show_hide_metrics",
				iconKey: "menu",
				toolPanel: AddHideMetrics,
				toolPanelParams: {
					onChange: true,
					tableRef: gridRef,
					groupBy: props.groupBy,
					subGroupBy: props.subGroupBy,
					subRowHideOnAllRows: props.subRowHideOnAllRows,
					showHideOnOtherGroups: props.showHideOnOtherGroups,
					sub_metric_level: props.sub_metric_level,
					subMetricTitle: props.subMetricTitle,
					metricTitle: props.metricTitle,
					isActiveGroupkey: props.isActiveGroupkey,
				},
			},
		]
		if(props?.isToolPanelFiltersNotRequired){
			toolPanel = [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
				},
				{
					id: "show_hide_metrics",
					labelDefault: props.subRowHideOnAllRows
						? "Show/Hide Rows"
						: "Show/Hide Metrics",
					labelKey: "show_hide_metrics",
					iconKey: "menu",
					toolPanel: AddHideMetrics,
					toolPanelParams: {
						onChange: true,
						tableRef: gridRef,
						groupBy: props.groupBy,
						subGroupBy: props.subGroupBy,
						subRowHideOnAllRows: props.subRowHideOnAllRows,
						showHideOnOtherGroups: props.showHideOnOtherGroups,
						sub_metric_level: props.sub_metric_level,
						subMetricTitle: props.subMetricTitle,
						metricTitle: props.metricTitle,
						isActiveGroupkey: props.isActiveGroupkey,
					},
				},
			]
		}
		let params = {
			toolPanels: toolPanel,
			//   defaultToolPanel: 'filters',
		};
		if (props.isToolPanelRequired) {
			params = {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Show/Hide metrics",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel",
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel",
					},
				],
			};
		}
		if (props?.isTableActionsRequired) {
			params = {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel",
					},
					{
						id: "table_actions",
						labelDefault: "Table Actions",
						labelKey: "table_actions",
						toolPanel: tableActionsToolPanel,
					},
				],
			};
		}
		return params;
	}, []);

	const sidePanel = useMemo(() => {
		let toolPanel = [
			{
				id: "columns",
				labelDefault: "Columns",
				labelKey: "columns",
				iconKey: "columns",
				toolPanel: "agColumnsToolPanel",
			},
			{
				id: "filters",
				labelDefault: "Filters",
				labelKey: "filters",
				iconKey: "filter",
				toolPanel: "agFiltersToolPanel",
			},
			{
				id: "show_hide_metrics",
				labelDefault: props.subRowHideOnAllRows
					? "Show/Hide Rows"
					: "Show/Hide Metrics",
				labelKey: "show_hide_metrics",
				iconKey: "menu",
				toolPanel: AddHideMetrics,
				toolPanelParams: {
					onChange: true,
					tableRef: gridRef,
					groupBy: props.groupBy,
					subGroupBy: props.subGroupBy,
					subRowHideOnAllRows: props.subRowHideOnAllRows,
					showHideOnOtherGroups: props.showHideOnOtherGroups,
					sub_metric_level: props.sub_metric_level,
					subMetricTitle: props.subMetricTitle,
					metricTitle: props.metricTitle,
					isActiveGroupkey: props.isActiveGroupkey,
				},
			},
			{
				id: "table_actions",
				labelDefault: "Table Actions",
				labelKey: "table_actions",
				toolPanel: tableActionsToolPanel,
			},
		]
		const hiddenPanels = new Set(props?.hiddenPanels)
		const params = {
			toolPanels: toolPanel.filter(panel => !hiddenPanels.has(panel.id))
		}
		return params
	}, [])
	
	function isExternalFilterPresent() {
		return true;
	}

	function doesExternalFilterPass(node) {
		if (node.data.hasOwnProperty("#hidden")) {
			return node.data["#hidden"] !== "Y";
		}
		return !node.data.hide;
	}

	const expandParentGroup = useCallback(() => {
		gridRef.current.api.forEachNode((node) => {
			if (node.level === 0) {
				node.setExpanded(true);
			}
		});
	}, []);

	const expandChildGroup = useCallback(() => {
		if (gridRef && gridRef.current && gridRef.current.api) {
			gridRef.current.api.forEachNode((node) => {
				if (props.childGroupDefaultOpenOptions.indexOf(node.key) !== -1) {
					node.parent.setExpanded(true); // ensure parent 'country' group is also expanded
					node.setExpanded(true);
				}
			});
		}
	}, []);

	const rowClassRules = useMemo(() => {
		return {
			// row style function
			"ia-forecast": (params) => {
				return (
					props.isColorCodingRequired &&
					params.data &&
					(params.data["ref"] === "WP" || params.data["ref"] === "WF")
				);
			},
			"ia-assort-carryover": (params) => {
				return (
					props.isColorCodingRequired &&
					params.data &&
					(params.data["id"] === "8" ||
						params.data["id"] === "9" ||
						params.data["choice_id"] === "Total")
				);
			},
			"ia-assort-line-review": (params) => {
				return (
					props.isColorCodingRequired &&
					params.data &&
					(params.data["id"] === "3" ||
						params.data["identifier"] === "2" ||
						params.data["identifier"] === "3")
				);
			},
		};
	});
	const aggFuncs = useMemo((params) => {
		return {
			mySum: (params) => {
				let sum = 0;
				params.values.forEach((value) => {
					return (sum +=
						typeof value === "number"
							? value
							: parseInt(value?.replaceAll(",", "")));
				});
				return currencyFormatter({ value: sum });
			},
			myAvg: (params) => {
				let sum = 0;
				params.values.forEach((value) => {
					return (sum +=
						typeof value === "number"
							? value
							: parseInt(value?.replaceAll(",", "")));
				});
				return currencyFormatter({ value: sum / params.values.length });
			},
			myAvgNormal: (params) => {
				let sum = 0;
				params.values.forEach((value) => {
					sum +=
						typeof value === "number"
							? value
							: parseInt(value?.replace(/,|\$/g, ""));
				});
				if (params.colDef?.format === "cur") {
					return currencyFormatter({ value: sum / params.values.length });
				}
				return NoFormatter({ value: sum / params.values.length });
			},
			myAvgPercent: (params) => {
				let sum = 0;
				params.values.forEach((value) => {
					return (sum +=
						typeof value === "number"
							? value
							: parseInt(value?.replaceAll(",", "")));
				});
				return percentageFormatter({ value: sum / params.values.length });
			},
			mySumNormal: (params) => {
				let sum = 0;
				let formatterType = null;
				params.values.forEach((value) => {
					let updatedValue = value;
					if (value && value !== "number") {
						updatedValue = updatedValue?.replaceAll("%", "");
						updatedValue = updatedValue?.replaceAll(",", "");
						updatedValue = updatedValue?.replaceAll("$", "");
						updatedValue = parseInt(updatedValue);
						if (value.includes("%")) {
							formatterType = "percentageFormatter";
						} else if (value.includes("$")) {
							formatterType = "currencyFormatter";
						}
					}
					return (sum += typeof value === "number" ? value : updatedValue);
				});

				if (formatterType === "percentageFormatter") {
					return percentageFormatter({ value: sum });
				} else if (formatterType === "currencyFormatter") {
					return currencyFormatter({ value: sum });
				} else {
					return NoFormatter({ value: sum });
				}
			},
			avgPercent: (params) => {
				let sum = 0;
				let formatterType = null;
				params.values.forEach((value) => {
					let updatedValue = value;
					if (value && value !== "number") {
						updatedValue = updatedValue?.replaceAll("%", "");
						updatedValue = updatedValue?.replaceAll(",", "");
						updatedValue = updatedValue?.replaceAll("$", "");
						updatedValue = parseInt(updatedValue);
						if (value.includes("%")) {
							formatterType = "percentageFormatter";
						} else if (value.includes("$")) {
							formatterType = "currencyFormatter";
						}
					}
					return (sum += typeof value === "number" ? value : updatedValue);
				});

				if (formatterType === "percentageFormatter") {
					return percentageFormatter({ value: sum / params.values.length });
				} else if (formatterType === "currencyFormatter") {
					return currencyFormatter({ value: sum / params.values.length });
				} else {
					return NoFormatter({ value: sum / params.values.length });
				}
			},
			defaultAgg: (params) => {
				const defaultAggData = params?.colDef.aggData;
				return (
					defaultAggData[`${params.rowNode?.key}`] &&
					defaultAggData[`${params.rowNode?.key}`][`${params.colDef.field}`]
				);
			},
		};
	}, []);

	const createData = () => {
		let topPinnedRowData = [];
		props.pinnedTopRow.forEach((obj) => {
			let rowDataObj = {};
			if (
				tableInfo[`${props.apikey}`] &&
				tableInfo[`${props.apikey}`].length > 0
			) {
				let rowInfo = tableInfo[`${props.apikey}`].filter((filterObj) => {
					return filterObj[`${obj.headerKey}`] === obj.headerName;
				});
				if (rowInfo.length > 0) {
					topPinnedRowData.push(rowInfo[0]);
				}
			}
		});
		return topPinnedRowData;
	};
	const pinnedTopRowData = useMemo(() => {
		if (props.pinnedTopRow && props.pinnedTopRow.length > 0) {
			return createData();
		} else {
			return [];
		}
	}, []);

	// const detailCellRendererParams = useMemo(() => {
	const detailCellRendererParams = () => {
		return {
			detailGridOptions: {
				columnDefs: props.detailGridOptions.column,
				defaultColDef: {
					flex: 1,
				},
			},
			getDetailRowData: (params) => {
				params.successCallback(
					tableInfo[`${props.detailGridOptions.key}`].filter(
						(obj) => obj.rowMasterKey === params.data.id
					)
				);
			},
		};
	};
	//   }, []);

	const isRowMaster = useMemo(() => {
		return (dataItem) => {
			return dataItem
				? dataItem.isRowMaster === "TRUE"
					? true
					: false
				: false;
		};
	}, []);

	return (
    <div
      id="myGrid"
      style={{
        height: props.height || "300px",
        width: props.width || "100%",
      }}
      className={
        isImagePresent()
          ? "ag-theme-custom-react-image impact-table-container-style"
          : "ag-theme-custom-react impact-table-container-style"
      }
    >
      <AgGridReact
        ref={gridRef}
        {...props}
        onGridReady={onGridReady}
        rowSelection={props.rowSelection}
        suppressRowClickSelection={true}
        columnDefs={columnDefs}
        defaultColDef={{ ...defaultColDef }}
        groupSelectsChildren={true}
        pagination={props.pagination}
        paginationAutoPageSize={true}
        paginateChildRows={true}
        // autoGroupColumnDef={props.autoGroupColumnDef}
        autoGroupColumnDef={props?.groupColumnName?props.autoGroupColumnDef:{headerName: ""}}
        rowData={props.childTableData || rowData}
        headerHeight={headerHeight || 37}
        rowHeight={rowHeight || null}
        // masterDetail={true}
        aggFuncs={aggFuncs}
        suppressAggFuncInHeader={true}
        onFirstDataRendered={onFirstDataRendered}
        floatingFilter={props.floatingFilter}
        cacheQuickFilter={true}
        suppressMenuHide={true}
        getRowStyle={getRowStyle}
        frameworkComponents={{
          CustomRenderComponent: CustomRendererComponent,
          EditWeekData: EditWeekData,
          EditTextField: (params) => EditTextField(params, props),
          CellTextField: CellTextField,
          CellImageField: CellImageField,
          CellPopOverImageField: CellPopOverImageField,
          DropdownCellRenderer: DropdownCellRenderer,
          CellSwitchField: CellSwitchField,
          PreformattedText: PreformattedText,
          CellAddIcon: CellAddIcon,
          CellInfoIcon: CellInfoIcon,
          CellMultiSelectDropdown: CellMultiSelectDropdown,
          CellChips: CellChips,
          CircularColorChips: CircularColorChips,
          CellColor: CellColor,
          ColumnColorRange: ColumnColorRange,
          CellPopOverAction: CellPopOverAction,
          CellMultipleSelect: CellMultipleSelect,
          CellDropdownMap: CellDropdownMap,
          CellDropdownMulti: CellDropdownMulti,
          CellGroupSet: CellGroupSet,
          CellRendererSelector: CellRendererSelector,
          CustomMultiComponentCell: CustomMultiComponentCell,
          CellCompute: CellCompute,
          CustomGroupCellRenderer: GroupCellRenderer,
          CellToggle: CellToggle,
          CellDateRange: CellDateRange,
          NestedTable: NestedTable
        }}
        onCellValueChanged={cellValueChanged}
        onCellClicked={onCellClicked}
        context={context}
        onSelectionChanged={onSelectionChanged}
        isExternalFilterPresent={isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        {...gridOptions}
        rowClassRules={rowClassRules}
        pinnedTopRowData={pinnedTopRowData}
        // sideBar={(props.sideBar || props.showHideMetrics ) && sideBar}
        sideBar={props.sidePanel?sidePanel :
          ((props.sideBar === undefined || !!props.sideBar 
            ? true
            : false) &&
          (props.showHideMetrics === undefined || !!props.showHideMetrics 
            ? true
            : false) &&
          sideBar)
        }
        masterDetail={
          props.detailGridOptions &&
          Object.keys(props.detailGridOptions).length > 0
            ? true
            : false
        }
        isRowMaster={
          props.detailGridOptions &&
          Object.keys(props.detailGridOptions).length > 0
            ? isRowMaster
            : null
        }
        detailCellRendererParams={
          props.detailGridOptions &&
          Object.keys(props.detailGridOptions).length > 0
            ? detailCellRendererParams
            : null
        }
        onRowDataChanged={onRowDataChanged}
        tooltipShowDelay={500}
        // pinnedTopRowData={pinnedTopRowData}
      />
    </div>
  );
};

export default Table;
