import { useState, useEffect } from "react";
export default function RowExpander(params) {
  const [expand, setExpand] = useState(params.node.expanded);
  const handleExpand = () => {
    console.log(params.node);
    setExpand((prev) => !prev);
  };
  function getArrow(value) {
    return " " + (value ? "\u23f6" : "\u23f7");
  }
  useEffect(() => {
    params.api.setRowNodeExpanded(params.node, expand);
  }, [params, expand]);
  return (
    <span onClick={handleExpand}>
      {params.node.allLeafChildren.length}
      {getArrow(expand)}
    </span>
  );
}
