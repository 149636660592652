import React, { useState, useEffect } from "react";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import moment from "moment";
import useAction from "../../../utils/useAction";
import { useDispatch, useSelector } from "react-redux";

const CustomDateRange = (props) => {
	const { tableInfo, selectedRowInfo, buffer } = useSelector(
		(state) => state.home
	);
	const dispatch = useDispatch();
	const invokeAction = useAction();

	const { data } = props;
	console.log(data, "data is here::::::::::98898");
	const state = [
		new Date(moment(data.defaultFromDate)),
		new Date(moment(data.defaultToDate)),
	];

	const [dates, setDates] = useState(state);

	const handleDateRangeChange = (values, data) => {
		setDates(values);
		if (data.isStoreRequired) {
			const defaultFormat = data?.format || "MM-DD-YYYY";
			const date = `${moment(values[0]).format(defaultFormat.toUpperCase())} - ${moment(
				values[1]
			).format(defaultFormat.toUpperCase())}`;
			dispatch({
				type: "ADD_TO_BUFFER",
				payload: { [data.bufferKey]: date },
			});
		}
	};

	useEffect(() => {
		if (data.action) {
			invokeAction(data.action);
		}
	}, [buffer]);
	return (
		<>
			<CustomDateRangePicker
				label={data.label}
				labeldirection={data?.labeldirection || "column"}
				values={dates}
				isMandatory={data?.isMandatory || false}
				width="4rem"
				format={data.format || "yyyy-MM-dd"}
				onChange={(values) => handleDateRangeChange(values, data)}
				iconStyle={data.iconStyle || {}}
				{...data}
			/>
		</>
	);
};
export default CustomDateRange;
