import CellDateRange from "./CellDateRange";
import CellGroupSet from "./CellGroupSet";
import CellRendererSelector from "./CellRendererSelector";
import CellToggle from "./CellToggle";
import { CustomRendererComponent } from "./CustomRendererComponent";
import RowExpander from "./RowExpander";
import DropdownCellRenderer from "./dropdownCellRender";

export default function GroupCellRenderer(params) {
    function getGroupObject(params) {
        switch (params.groupRendererType) {
            case "groupSet":
                return <CellGroupSet {...params} />
            case "toggle":
                return <CellToggle {...params} />
            case "rowExpander":
                return <RowExpander {...params} />
            case "customRenderer":
                return <CustomRendererComponent {...params} />
            case "dateRange":
                return <CellDateRange {...params} />
            case "dropdown":
                return <DropdownCellRenderer {...params} />
            case "empty":
                return <></>
            default:
                const firstLeafNode = params.node.allLeafChildren[0];
                const value = firstLeafNode.data[params.colDef.field]
                return <>{value}</>
        }
    }
    function getLeafObject(params) {
        switch (params.leafRendererType) {
            case "selector":
                return <CellRendererSelector{...params} />;
            case "empty":
                return <></>;
            default:
                return <>{params.value}</>
        }
    }
    return (
        params.node.group ?
            getGroupObject(params) :
            getLeafObject(params)
    )
}