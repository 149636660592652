import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { Tooltip, Badge } from "impact-ui";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CampaignIcon from "@mui/icons-material/Campaign";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { toDollarWithDecimalFormatted } from "../formatters";

export const GetDetailContent = (event) => {
  const currDt = new Date();
  return (
    <div className="event-content">≠
      <div className="event-title-wrapper">
        <span className="status-indicator"></span>
        <p className="event-title">{event.name}</p>
      </div>
      <div className="event-detail">
        {event.eventRunningStatus == "Upcoming" &&
        moment().isBefore(moment(event.submit_offer_by)) &&
        moment(event.submit_offer_by).diff(moment(currDt), "days") < 5 ? (
          <Badge
            label={`${moment(event.submit_offer_by).diff(
              moment(currDt),
              "days"
            )} days to submission lock`}
            variant="error"
            outline
          />
        ) : null}
      </div>
      <div className="event-detail">
        <CalendarMonthIcon />
        <p>
          {event.start_date} - {event.end_date}
        </p>
      </div>
      {event.description ? (
        <div className="event-detail">
          <DescriptionOutlinedIcon />
          <p>Description - {event.description}</p>
        </div>
      ) : null}
      {event.event_name ? (
        <div className="event-detail">
          <DescriptionOutlinedIcon />
          <p>Event Name - {event.event_name}</p>
        </div>
      ) : null}
      {event.notes ? (
        <div className="event-detail">
          <NoteAltIcon />
          <p>Notes - {event.notes}</p>
        </div>
      ) : null}
      {event.products ? (
        <div className="event-detail">
          <Inventory2OutlinedIcon />
          <p>Products - {event.products}</p>
        </div>
      ) : null}
      {event.promo ? (
        <div className="event-detail">
          <CampaignIcon />
          <p>Promo - {event.promo}</p>
        </div>
      ) : null}
      {event.offer ? (
        <div className="event-detail">
          <LocalOfferOutlinedIcon />
          <p>Offer - {event.offer}</p>
        </div>
      ) : null}
      <div className="event-detail">
        <AttachMoneyIcon />
        <p>Markdown$ - {event.markdown$}</p>
      </div>
      <div className="event-detail">
        <EventNoteIcon />
        <p>Status - {event.eventRunningStatus}</p>
      </div>
      <div className="event-footer">
        <Tooltip text="Lock" placement="top">
          <LockOpenIcon
            sx={{
              height: "16px",
              width: "16px",
            }}
            //onClick={() => props.handleLockEvent(event)}
            className={
              moment(currDt).isSameOrAfter(moment(event.start_date))
                ? "disabled-lock"
                : ""
            }
            disabled={moment(currDt).isSameOrAfter(moment(event?.start_date))}
          />
        </Tooltip>
        <React.Fragment>
          <Tooltip text="View Promos" placement="top">
            <VisibilityIcon />
          </Tooltip>
          <Tooltip text="Edit" placement="top">
            <EditIcon
              disabled={moment(currDt).isSameOrAfter(moment(event?.start_date))}
            />
          </Tooltip>
          <Tooltip text="Delete" placement="top">
            <DeleteIcon
              disabled={moment(currDt).isSameOrAfter(moment(event?.start_date))}
            />
          </Tooltip>
        </React.Fragment>
      </div>
    </div>
  );
};
