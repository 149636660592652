import React, { useState } from "react"
import Switch from "@mui/material/Switch";
import { useEffect } from "react";
import useAction from "../../../utils/useAction";

export default function CellToggle(params) {
    const invokeAction = useAction()
    const [checked, setChecked] = useState(() => {
        const value = params.node.group ? params.node.allLeafChildren[0].data[params.colDef.field] : params.value
        return value === "1" ? true : false
    })
    function handleToggle(e) {
        setChecked(prev => !prev)
        action(checked)
    }
    useEffect(() => {
        if (params.updateParentGrid) {
            const v = checked ? "1" : "0"
            params.setValue(v)
        }
    }, [params, checked])

    function action(currentState) {
        if (params.switchAction) {
            const action = currentState ? params.switchAction[0] : params.switchAction[1]
            invokeAction(action)
        }
    }
    return (
            <Switch
                checked={checked}
                onClick={(e) => {
                    handleToggle(e);
                }}
            />
    );
}