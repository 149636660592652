import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
const customStyles = {
	control: (provided, state) => ({
		...provided,
		borderColor: state.isFocused ? "black" : provided.borderColor,
		boxShadow: state.isFocused ? "none" : provided.boxShadow,
		height: "25px",
		width: "120px",
	}),
	menu: (provided) => ({
		...provided,
		zIndex: 9999,
		width: "150px",
	}),
	option: (provided) => ({
		...provided,
		fontSize: "0.75rem",
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: "none",
	}),
	menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};
const getOptions = (optionsStr) => {
	const options = optionsStr.trim().split(",");
	return options.map((str) => {
		const value = str.trim();
		return {
			value,
			label: value,
		};
	});
};

function CellDropdown(props) {
	console.log(props, "props in map cell dropdown is::");
	const dispatch = useDispatch();
	const { tableInfo } = useSelector((state) => state.home);
	const [dropdowns] = useState(() => {
		const data = [];
		for (let i = 0; i < props.dropdownsCount; i++) {
			let containers = props?.data?.[`${props.colDef.field}#options_${i}`];
			if (!containers || containers === "not-valid") break;
			containers = containers.trim()?.split(":");
			containers = containers.map((optionsStr) => getOptions(optionsStr));
			data.push(containers);
		}
		const reservedOptionsLengthCache = {};
		function getMappedOptions(optionsArrayIndex, optionsIndex) {
			const mappedOptions = [];
			const arr =
				(data[optionsArrayIndex] && data[optionsArrayIndex][optionsIndex]) ||
				[];
			let reservedOptionsLength =
				reservedOptionsLengthCache[optionsArrayIndex] || 0;
			reservedOptionsLengthCache[optionsArrayIndex] =
				reservedOptionsLength + arr.length;
			for (let i = 0; i < arr.length; i++) {
				mappedOptions.push({
					...arr[i],
					mappedOptions: getMappedOptions(
						optionsArrayIndex + 1,
						reservedOptionsLength + i
					),
				});
			}
			return mappedOptions;
		}
		return getMappedOptions(0, 0);
	});

	const [selected, setSelected] = useState([]);

	const handleChange = useCallback(
		(newOption, index, params) => {
			const arr = [...selected];
			arr[index] = newOption;
			const rows = [...tableInfo[params.parent_table_key]];
			let row = null;
			if (params.data.hasOwnProperty("id")) {
				row = rows.find((row) => row.id === params.data.id);
			} else {
				row = rows[params.rowIndex];
			}

			let fieldLabel = arr.map((obj) => obj?.label || "").join(",");
			row[params.colDef.field] = `component-dropdownGroup-${fieldLabel}`;

			dispatch({
				type: "TABLE_DATA",
				payload: { [params.parent_table_key]: [...rows] },
			});
			params.api.stopEditing();
		},
		[selected, tableInfo]
	);

	const selects = useMemo(() => {
		function parse(options, index) {
			const value =
				(selected.length > 0 && selected[index]) ||
				(options.length > 0 && options[0]) ||
				"";
			return (
				<>
					<Select
						options={options}
						value={value}
						onChange={(value) => handleChange(value, index, props)}
						styles={customStyles}
						menuShouldBlockScroll={true}
						menuPlacement="auto"
						menuPortalTarget={document.body}
						isSearchable={false}
						maxMenuHeight={200}
					/>
					{index + 1 < props.dropdownsCount
						? parse(value.mappedOptions || [], index + 1)
						: null}
				</>
			);
		}
		return parse(dropdowns, 0);
	}, [selected, dropdowns, props]);

	useEffect(() => {
		const labels = props.value?.trim().split(",");
		setSelected((prev) => {
			const arr = [];
			let options = dropdowns;
			for (let i = 0; i < props.dropdownsCount; i++) {
				const found = options.find(
					(option) => option.label === labels[i]?.trim() || null
				);
				if (found) {
					arr.push(found);
					options = found.mappedOptions;
					continue;
				}
				arr.push(i === 0 ? prev[i] : options[0]);
				break;
			}
			return arr;
		});
	}, [props]);
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				gap: "5px",
				position: "relative",
			}}>
			{selects}
		</div>
	);
}
export default function Renderer(props) {
	if (props?.data?.[`#hide_dropdown_${props.colDef.field}`] === "Y") {
		return "";
	} else if (
		props?.data?.[`#disable_dropdown`] === "Y" ||
		props?.data?.[`#disable_dropdown${props.colDef.field}`] === "Y"
	) {
		return props.value;
	}
	return CellDropdown(props);
}
